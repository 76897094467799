import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  DataStore,
  DataStoreKey,
} from '@compiere-ws/models/compiere-data-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import {
  CustomDesignItem,
  CustomDesignItemType,
} from '@iupics-components/models/custom-design';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import {
  CacheManagerService,
  TableName,
} from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import {
  getHeaderCustomDesignItems,
  getLineCustomDesignItems,
  getNullValueInData,
  getPaymentLineRequest,
  getPaymentRequest,
  getTabDataRequest,
} from './fast-create-expense-revenue-utils';
@Component({
  selector: 'iu-fast-create-expense-revenue',
  templateUrl: './fast-create-expense-revenue.component.html',
  styleUrls: ['./fast-create-expense-revenue.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// todo flex du deuxieme table
export class FastCreateExpenseRevenueComponent
  extends SpecificWindowUiComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('linesContainer', { read: ElementRef, static: true })
  linesContainer: ElementRef;

  @ViewChildren('vcrOrgs', { read: ViewContainerRef })
  vcrOrgs: QueryList<ViewContainerRef>;
  @ViewChildren('vcrPayDetailAmts', { read: ViewContainerRef })
  vcrPayDetailAmts: QueryList<ViewContainerRef>;
  @ViewChildren('vcrCharges', { read: ViewContainerRef })
  vcrCharges: QueryList<ViewContainerRef>;
  @ViewChildren('vcrUser1s', { read: ViewContainerRef })
  vcrUser1s: QueryList<ViewContainerRef>;
  @ViewChildren('vcrProjects', { read: ViewContainerRef })
  vcrProjects: QueryList<ViewContainerRef>;
  @ViewChildren('vcrLocals', { read: ViewContainerRef })
  vcrLocals: QueryList<ViewContainerRef>;
  @ViewChildren('vcrBeneficiaires', { read: ViewContainerRef })
  vcrBeneficiaires: QueryList<ViewContainerRef>;
  @ViewChildren('vcrUser2s', { read: ViewContainerRef })
  vcrUser2s: QueryList<ViewContainerRef>;
  @ViewChildren('vcrRessources', { read: ViewContainerRef })
  vcrRessources: QueryList<ViewContainerRef>;
  @ViewChildren('vcrDescriptions', { read: ViewContainerRef })
  vcrDescriptions: QueryList<ViewContainerRef>;

  @ViewChild('vcrCopyLines', { read: ViewContainerRef, static: true })
  vcrCopyLines: ViewContainerRef;
  @ViewChild('vcrBankCC', { read: ViewContainerRef, static: true })
  vcrBankCC: ViewContainerRef;
  @ViewChild('vcrBankAccount', { read: ViewContainerRef, static: true })
  vcrBankAccount: ViewContainerRef;
  @ViewChild('vcrBankDesc', { read: ViewContainerRef, static: true })
  vcrBankDesc: ViewContainerRef;
  @ViewChild('vcrDocumentNo', { read: ViewContainerRef, static: true })
  vcrDocumentNo: ViewContainerRef;
  @ViewChild('vcrDocType', { read: ViewContainerRef, static: true })
  vcrDocType: ViewContainerRef;
  @ViewChild('vcrSubPaymentRule', { read: ViewContainerRef, static: true })
  vcrSubPaymentRule: ViewContainerRef;
  @ViewChild('vcrDateAcct', { read: ViewContainerRef, static: true })
  vcrDateAcct: ViewContainerRef;
  @ViewChild('vcrDescription', { read: ViewContainerRef, static: true })
  vcrDescription: ViewContainerRef;
  @ViewChild('vcrCheckNo', { read: ViewContainerRef, static: true })
  vcrCheckNo: ViewContainerRef;
  @ViewChild('vcrPayAmt', { read: ViewContainerRef, static: true })
  vcrPayAmt: ViewContainerRef;
  @ViewChild('vcrCurrency', { read: ViewContainerRef, static: true })
  vcrCurrency: ViewContainerRef;
  @ViewChildren('vcrOthers', { read: ViewContainerRef })
  vcrsOthers: QueryList<ViewContainerRef>;
  @ViewChild('vcrSettings', { read: ViewContainerRef, static: true })
  vcrSettings: ViewContainerRef;

  isSidebarOpen = false;
  subscription;
  docStatus = '*';
  // AD
  tableID;
  paymentTabID;
  paymentLineTabID;
  windowID;

  displayJoinFilesPanel = false;
  nbUploadedFiles = 0;
  dsKey: DataStoreKey;
  placeHolder = '';
  currency = '';
  lineMetaData: CustomDesignItem[] = [];

  dataLines: DataStore[] = [];
  dataContainersLine: AbstractDataContainer[][] = [];
  tabindex = 1;
  grandTotal = 0;
  grandTotalData = { numberType: NumberType.AMOUNT };
  ad_language = 'fr_FR';
  isProcessed = false;
  displayProcessUI = false;
  processId = null;
  MANDATORY_HEADER_COLUMNS = [
    'AD_Client_ID',
    'AD_Org_ID',
    'Z_Bank_CC_ID',
    'C_BankAccount_ID',
    'C_DocType_ID',
    'ZSubPaymentRule_ID',
    'DateAcct',
    'Description',
  ];
  translations: any;
  nbLines = 1;
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    private poService: PoService,
    private messageManager: MessageManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    private dataService: CompiereDataService,
    protected cacheService: CacheManagerService,
    private workflowService: CompiereWorkflowService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.docStatus = this.translateService.instant(
      'specificWindow.quickExpenseRevenue.new'
    );
    this.isModal = false;
    this.customDesignArray.push(...getHeaderCustomDesignItems());
  }
  ngOnInit() {
    super.ngOnInit();
    this.initTranslations();
    this.ad_language =
      this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.lineMetaData = getLineCustomDesignItems();
    // INIT
    this.initAD_Data();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: '',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  ngAfterViewInit() {
    this.dataStore.dataChange.subscribe((dataChanged) => {
      Object.keys(dataChanged.dataModified).forEach((columnName) => {
        if (columnName === 'C_Currency_ID') {
          this.notifyFromDataChange({
            data: {
              columnName: columnName,
              id: dataChanged.dataModified['C_Currency_ID'].id,
            },
          });
        }
      });
    });
    // setFocused for scan
    const ctx = this.getCurrentContext();
    if (ctx['C_Currency_ID']) {
      this.setCurrencySymbol(ctx['C_Currency_ID']);
    } else if (ctx['$C_Currency_ID']) {
      this.setCurrencySymbol(ctx['$C_Currency_ID']);
    }
  }
  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */
  getCurrentContext(dataStore?: DataStore, dataStoreParent?: DataStore) {
    let specificParent;
    let editViewParent;
    if (
      this.parentComponent &&
      this.parentComponent.editTabs &&
      this.parentComponent.editTabs[0]
    ) {
      editViewParent = this.parentComponent;
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      specificParent = this.parentComponent;
    }
    /*merge avec le contexte du parent */
    if (dataStoreParent) {
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore.data : this.dataStore.data),
        cloneDeep(dataStoreParent ? dataStoreParent.data : this.dataStore.data)
      );
    }
    if (specificParent) {
      const specificParentContext = specificParent.getCurrentContext();
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore : this.dataStore.data),
        specificParentContext
      );
    } else {
      return EditViewUtils.getCurrentContext(
        editViewParent,
        dataStore ? dataStore : this.dataStore,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  updateDisplayLogicOnButton(columnName: string, isDisplayed = true) {
    const buttonComponent = <ButtonUiComponent>(
      this.dataContainers.find(
        (datacontainer) =>
          datacontainer.data && datacontainer.data.columnName === columnName
      )
    );
    if (buttonComponent) {
      buttonComponent.data.DisplayLogic = isDisplayed ? '1=1' : '1=2';
    }
  }
  initTranslations() {
    this.translations = {
      org: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.org'
      ),
      payDetailAmt: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.payDetailAmt'
      ),
      charge: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.charge'
      ),
      user1: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.user1'
      ),
      project: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.project'
      ),
      local: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.local'
      ),
      beneficiaire: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.beneficiaire'
      ),
      user2: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.user2'
      ),
      ressource: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.ressource'
      ),
      payAmtTot: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.payAmtTot'
      ),
      description: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.description'
      ),
      payAmt: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.payAmt'
      ),
      immoType: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.immoType'
      ),
      financeur: this.translateService.instant(
        'specificWindow.quickExpenseRevenue.lineData.financeur'
      ),
    };
  }

  updateHeader() {
    this.grandTotal = 0;
    this.dataLines.forEach((line) => {
      this.grandTotal =
        this.grandTotal +
        (line.data['PayDetailAmt'] != null &&
        line.data['PayDetailAmt'] != undefined
          ? line.data['PayDetailAmt']
          : 0);
    });
  }
  resetlines(nbLines = 1) {
    // Reset lines
    this.dataLines.forEach((dataLine, index) => {
      this.lineMetaData.forEach((metaData) => {
        this[metaData.vcr].toArray()[index].clear();
      });
    });
    this.dataLines = [];
    this.nbLines = nbLines;
    this.dataContainersLine = [];
  }
  resetPayment() {
    this.isProcessed = false;
    this.updateDisplayLogicOnButton('CreateFrom', true);
    this.resetlines();
    // this.dataStore.dataChange.unsubscribe();
    this.dataStore = this.store.newSpecificWindowData(this.formId);
    this.dataContainers.forEach((dataContainer) => {
      dataContainer.setNewData(this.dataStore);
    });

    this.grandTotal = 0;
    this.nbUploadedFiles = 0;
    const ctx = this.getCurrentContext();
    if (ctx['C_Currency_ID']) {
      this.setCurrencySymbol(ctx['C_Currency_ID']);
    } else if (ctx['$C_Currency_ID']) {
      this.setCurrencySymbol(ctx['$C_Currency_ID']);
    }
    this.docStatus = this.translateService.instant(
      'specificWindow.quickOrder.new'
    );
  }
  //#region override
  public updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      if (
        display.key === 'displayProcessUI' &&
        display.value === false &&
        this['isProcessLaunch'] !== false
      ) {
        this.refreshPayment();
      }
      // to avoid refreshline when we open productview
      this['isProcessLaunch'] = true;
      this.customFormModalBodyCss = undefined;
    }
  }
  processCopyLines(item: any) {
    // to override button process behaviour
    this.checkMissingField();
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Payment_ID'];
  }
  /**
   * Overrided to avoid std behaviour of datacontainer in specific window
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {}

  notifyFromDataChange(item: any, vcrIndex?: number) {
    let lineDataStore = null;
    if (vcrIndex !== undefined) {
      lineDataStore = this.dataLines[vcrIndex];
    }
    if (
      [
        'C_DocType_ID',
        'C_BankAccount_ID',
        'DateTrx',
        'PayAmt',
        'C_Currency_ID',
        'OverUnderAmt',
      ].includes(item.data['columnName']) &&
      this.dataStore &&
      this.dataStore.data[item.data['columnName']]
    ) {
      this.calloutColumn(item.data['columnName']);
    } else if (
      item.data['columnName'] === 'C_BPartner_ID' &&
      lineDataStore &&
      lineDataStore.data['C_BPartner_ID']
    ) {
      this.calloutColumnLine('C_BPartner_ID', lineDataStore);
    } else if (
      item.data['columnName'] === 'C_Charge_ID' &&
      lineDataStore &&
      lineDataStore.data['C_Charge_ID']
    ) {
      this.calloutColumnLine('C_Charge_ID', lineDataStore);
    } else if (item.data['columnName'] === 'C_Currency_ID') {
      if (item.data.id && item.data.id > 0) {
        this.setCurrencySymbol(item.data.id);
      }
    }
    if (
      item.data['columnName'] === 'C_Payment_ID' &&
      this.dataStore.data['C_Payment_ID'] !== null
    ) {
      this.docStatus = this.translateService.instant(
        'specificWindow.quickOrder.draft'
      );
    }

    let datacontainer = this.getDatacontainer('DateAcct');
    if (datacontainer) {
      datacontainer.updateStore(this.dataStore.data['DateAcct']);
    }
    if (
      item.data['columnName'] === 'PayDetailAmt' &&
      lineDataStore &&
      lineDataStore.data['PayDetailAmt'] !== null
    ) {
      this.updateHeader();
    }
    if (item.data['columnName'] != 'C_Charge_ID' && lineDataStore&& lineDataStore.data['Z_PaymentLine_ID']) {
      this.savePaymentLine(lineDataStore);
    }
    // autosave header
    if (
      ![
        'C_DocType_ID',
        'C_BankAccount_ID',
        'DateTrx',
        'PayAmt',
        'C_Currency_ID',
        'OverUnderAmt',
      ].includes(item.data['columnName'])
    ) {
      this.savePayment(true);
    }
  }

  //#endregion override
  //#region CALLOUT
  calloutColumn(columnName: string) {
    const value = this.dataStore.data[columnName].id
      ? this.dataStore.data[columnName].id
      : this.dataStore.data[columnName];
    this.store.calloutData(
      '/Field/Callout/C_Payment/' + columnName,
      {
        columnName: columnName,
        newValue: value,
        windowCtx: this.getCurrentContext(),
      },
      this.dataStore,
      null,
      () => {
        this.savePayment(true);
      }
    );
  }
  calloutColumnLine(columnName: string, lineDataStore: DataStore) {
    const value =
      lineDataStore.data[columnName] && lineDataStore.data[columnName].id
        ? lineDataStore.data[columnName].id
        : lineDataStore.data[columnName];
    this.store.calloutData(
      '/Field/Callout/Z_PaymentLine/' + columnName,
      {
        columnName: columnName,
        newValue: value,
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore),
      },
      lineDataStore,
      null,
      () => {
        if (lineDataStore?.data['Z_PaymentLine_ID']) {
          this.savePaymentLine(lineDataStore);
        }
      }
    );
  }

  //#endregion CALLOUT
  //#region CRUD
  //#region DocManagement
  cancelDoc() {
    if (
      this.dataStore &&
      this.dataStore.data &&
      this.dataStore.data['C_Payment_ID']
    ) {
      this.updateLoading(true);
      const sub$ = this.deleteDoc().subscribe((deleted) => {
        this.updateLoading(false);
        if (deleted) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.translateService.instant(
                'specificWindow.quickExpenseRevenue.deleteSuccess'
              ),
              'success'
            )
          );
          this.resetPayment();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.translateService.instant(
                'specificWindow.quickExpenseRevenue.deleteFailed'
              ),
              'error'
            )
          );
        }
        sub$.unsubscribe();
      });
    } else {
      this.resetPayment();
    }
  }
  processPayment() {
    const lineDataStore = this.dataLines.find(
      (line) => !line.data['Z_PaymentLine_ID']
    );
    if (lineDataStore) {
      this.savePaymentLine(lineDataStore, this.processPayment.bind(this));
    }
    this.savePayment(true, () => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (
        this.dataStore &&
        ((this.dataStore.data['DocStatus'] instanceof Object &&
          this.dataStore.data['DocStatus'].id !== 'CO') ||
          this.dataStore.data['DocStatus'] === 'DR')
      ) {
        let data = {};
        /*On ajoute le contexte utilisateur */
        const userContext = this.connectorService.getIupicsUserContext();
        const keys = Object.keys(userContext);
        keys.forEach((key) => {
          data[key] = cloneDeep(userContext[key]);
        });
        /*on merge le contexte avec le store du composant concerné et le contexte utilisateur */
        if (this.dataStore && this.dataStore.data) {
          data = EditViewUtils.mergeCurrentDataDeepCopy(
            data,
            this.dataStore.data
          );
        }
        this.updateLoading(true);
        let record_id = this.dataStore.data['C_Payment_ID'];
        if (record_id && record_id.id !== undefined) {
          record_id = record_id.id;
        }
        this.subscription = this.workflowService
          .runWF({
            record_id: String(record_id),
            windowCtx: data,
            action: 'CO',
            table_id: this.tableID,
            ad_process_id: '149',
          })
          .subscribe((response) => {
            this.updateLoading(false);
            if (response) {
              if (response.Success === true) {
                this.dataStore.data['DocStatus'] = response.DocStatus;
                if (response.DocStatus && response.DocStatus.id === 'CO') {
                  this.docStatus = this.translateService.instant(
                    'specificWindow.quickOrder.processed'
                  );
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('generic.success'),
                      this.translateService.instant(
                        'specificWindow.quickExpenseRevenue.validated'
                      ),
                      'success'
                    )
                  );
                } else {
                  this.docStatus = this.translateService.instant(
                    'specificWindow.quickOrder.inProgress'
                  );
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('generic.success'),
                      this.translateService.instant(
                        'specificWindow.quickExpenseRevenue.notValidated'
                      ),
                      'warning'
                    )
                  );
                }
              } else {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translateService.instant('generic.warning'),
                    response.Message
                  )
                );
              }
            }
          });
      }
    });
  }
  deleteDoc() {
    this.updateLoading(true);
    const paymentId =
      this.dataStore.data['C_Payment_ID'] instanceof Object
        ? this.dataStore.data['C_Payment_ID'].id
        : this.dataStore.data['C_Payment_ID'];
    const data_UUID_list = [];
    // DELETE PAYMENT LINE
    this.dataLines.forEach((lineDataStore) => {
      const paymentLineId =
        lineDataStore.data['C_Payment_ID'] instanceof Object
          ? lineDataStore.data['Z_PaymentLine_ID'].id
          : lineDataStore.data['Z_PaymentLine_ID'];
      if (paymentLineId) {
        data_UUID_list.push({ Z_PaymentLine_ID: paymentLineId });
      }
    });
    let obs = of([]);
    if (data_UUID_list.length > 0) {
      obs = this.dataService.deleteData(this.paymentLineTabID, data_UUID_list);
    }
    return obs.pipe(
      switchMap((resultLines) => {
        // CHECK ERROR DURING PAYMENTLINE DELETE
        let hasPaymentLineError = false;
        if (resultLines) {
          hasPaymentLineError =
            resultLines.find((resultLine) =>
              resultLine.messages.find((msg) => msg.type !== 'SUCCESS')
            ) !== undefined;
        } else {
          hasPaymentLineError = true;
        }
        if (resultLines) {
          // CHECK REMOVE DELETED LINES ON UI
          resultLines.forEach((line) => {
            if (line.messages.find((msg) => msg.type === 'SUCCESS')) {
              const index = this.dataLines.findIndex(
                (dataLine) =>
                  dataLine.data['Z_PaymentLine_ID'] == line['Z_PaymentLine_ID']
              );
              this.lineMetaData.forEach((metaData) => {
                this[metaData.vcr].toArray()[index].clear();
              });
              for (let i = index + 1; i < this.dataLines.length; i++) {
                this.lineMetaData.forEach((metaData) => {
                  const element = (<ViewContainerRef>(
                    this[metaData.vcr].toArray()[i]
                  )).detach();
                  (<ViewContainerRef>(
                    this[metaData.vcr].toArray()[i - 1]
                  )).insert(element);
                });
              }
              this.dataLines.splice(index, 1);
              this.dataContainersLine.splice(index, 1);
            }
          });
        }
        this.updateHeader();

        return of(!hasPaymentLineError);
      }),
      switchMap((isSuccess) => {
        if (isSuccess) {
          // DELETE PAYMENT
          return this.dataService
            .deleteData(this.paymentTabID, [{ C_Payment_ID: paymentId }])
            .pipe(
              map((results) => {
                let hasPaymentError = false;
                if (results) {
                  if (results.length > 0) {
                    hasPaymentError =
                      results.find((result) =>
                        result.messages.find((msg) => msg.type !== 'SUCCESS')
                      ) !== undefined;
                  }
                } else {
                  hasPaymentError = true;
                }
                return of(!hasPaymentError);
              }),
              catchError(() => {
                return of(false);
              })
            );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  //#endregion DocManagement
  savePayment(isAutoSave = false, callback: Function = null) {
    const nullValue = getNullValueInData(
      this.dataStore.data,
      this.MANDATORY_HEADER_COLUMNS.filter(
        (col) =>
          !['Z_PaymentCommunication'].includes(col) ||
          !['XX_BVR', 'Z_PaymentCommunication'].includes(col)
      )
    );
    if (nullValue == null) {
      const paymentId =
        this.dataStore.data['C_Payment_ID'] instanceof Object
          ? this.dataStore.data['C_Payment_ID'].id
          : this.dataStore.data['C_Payment_ID'];
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = undefined;
      }
      this.subscription = this.savePaymentInStore(
        this.dataStore,
        paymentId
      ).subscribe((value) => {
        if (value) {
          if (!isAutoSave) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.success'),
                this.translateService.instant(
                  'specificWindow.quickExpenseRevenue.saveSuccess'
                ),
                'success'
              )
            );
          }
          if (callback) {
            callback();
          }
        }
      });
    } else {
      this.checkMissingField(isAutoSave);
    }
  }
  checkMissingField(isAutoSave = false) {
    const nullValue = getNullValueInData(
      this.dataStore.data,
      this.MANDATORY_HEADER_COLUMNS
    );
    if (['AD_Client_ID', 'AD_Org_ID'].includes(nullValue)) {
      this.isSidebarOpen = true;
    }
    if (nullValue && !isAutoSave) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant(
            'specificWindow.quickExpenseRevenue.missingParams'
          ),
          'warning'
        )
      );
    }
  }
  savePaymentInStore(datastore: DataStore, id?: number, sync = true) {
    const dataReformat = Object.assign({}, datastore.data);
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_Payment', dataReformat, id).pipe(
      switchMap((result) => {
        // joinfile need dskey to get back oldcurrentstore info during taggedcolumns generation
        const paymentId =
          result['C_Payment_ID'] instanceof Object
            ? result['C_Payment_ID'].id
            : result['C_Payment_ID'];
        this.dsKey = {
          parentId: 'quickExpenseRevenue',
          recordId: `C_Payment_ID,${paymentId}`,
          tabId: this.paymentTabID,
          windowId: this.windowID,
        };
        datastore.key = this.dsKey;
        // Set data_uuid needed for joinfile
        datastore.data['Data_UUID'] = `C_Payment_ID,${paymentId}`;
        result['Data_UUID'] = `C_Payment_ID,${paymentId}`;
        datastore.key = null;
        if (sync) {
          this.store.syncDataChanges(datastore, result, false, true);
        } else {
          datastore.data = EditViewUtils.mergeCurrentDataDeepCopy(
            datastore.data,
            result
          );
        }
        datastore.key = this.dsKey;
        this.store.copyWindowDataToOldStore(datastore);
        datastore.key = null;
        return of(true);
      }),
      catchError((err) => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.error'),
            err.error.message,
            'error'
          )
        );
        return of(false);
      })
    );
  }

  getCreatedPaymentLines() {
    const paymentId =
      this.dataStore.data['C_Payment_ID'] instanceof Object
        ? this.dataStore.data['C_Payment_ID'].id
        : this.dataStore.data['C_Payment_ID'];
    return this.store
      .getDataGrid(getPaymentLineRequest(paymentId, this.ad_language), true)
      .pipe(
        switchMap((response) => {
          if (response && response.data) {
            this.resetlines(response.data.length + 1);
            setTimeout(() => {
              response.data.forEach((lineData) => {
                const lineDataStore = this.store.newSpecificWindowData(
                  this.formId,
                  this.dataStore
                );
                lineDataStore.data = lineData;
                this.dataLines.push(lineDataStore);
                this.lineMetaData.forEach((metaData) => {
                  const compRef = this.createCustomDesignItem(
                    metaData,
                    true,
                    this.dataLines.length - 1,
                    lineDataStore
                  );
                  // make autocomplete overlay visible outside overflow
                  compRef.instance['isInsideOverflow'] = true;
                  if (compRef) {
                    if (metaData.columnName !== 'Description') {
                      compRef.location.nativeElement.setAttribute(
                        'tabindex',
                        this.tabindex++
                      );
                    } else {
                      compRef.location.nativeElement.setAttribute(
                        'tabindex',
                        -1
                      );
                    }
                    this.dataContainers.pop();
                    (<AbstractDataContainer>compRef.instance).data[
                      'fieldValueModifiedSub'
                    ].unsubscribe();
                    (<AbstractDataContainer>(
                      compRef.instance
                    )).fieldValueModified.subscribe((dataStored) => {
                      const item = this.specificData.items.find(
                        (specificItem) => {
                          return metaData.type === CustomDesignItemType.FIELD
                            ? metaData.columnName ===
                                specificItem.data.columnName
                            : metaData.tableName === specificItem.name;
                        }
                      );
                      this.notifyFromDataChange(
                        item,
                        this.dataLines.indexOf(dataStored)
                      );
                    });
                    if (!this.dataContainersLine[this.dataLines.length - 1]) {
                      this.dataContainersLine[this.dataLines.length - 1] = [];
                    }
                    this.dataContainersLine[this.dataLines.length - 1].push(
                      <AbstractDataContainer>compRef.instance
                    );

                    (<AbstractDataContainer>(
                      compRef.instance
                    )).isSetDefaultValue = true;
                    (<AbstractDataContainer>compRef.instance).setNewData(
                      lineDataStore
                    );
                  }
                });
                this.store.syncDataChanges(lineDataStore, lineData);
              });
              this.updateHeader();
            }, 1000);
            return of(true);
          } else {
            return of(false);
          }
        }),
        catchError((err) => {
          return of(false);
        })
      );
  }

  refreshPayment(onlyHeader = false) {
    this.updateLoading(true);
    const paymentId =
      this.dataStore.data['C_Payment_ID'] instanceof Object
        ? this.dataStore.data['C_Payment_ID'].id
        : this.dataStore.data['C_Payment_ID'];
    let obs = of(true);
    if (!onlyHeader) {
      obs = this.getCreatedPaymentLines();
    }
    obs
      .pipe(
        switchMap((getLineSuccess) => {
          if (getLineSuccess) {
            return this.store.getDataGrid(
              getPaymentRequest(paymentId, this.ad_language)
            );
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant(
                  'specificWindow.quickExpenseRevenue.log.paymentLineNotFound'
                ),
                'error'
              )
            );
            return of(null);
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((paymentData) => {
        this.updateLoading(false);
        if (paymentData) {
          this.dataStore.data = paymentData.data[0];
          this.store.syncDataChanges(
            this.dataStore,
            paymentData.data[0],
            false,
            true
          );
          if (onlyHeader) {
            this.updateHeader();
          }
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.translateService.instant(
                'specificWindow.quickExpenseRevenue.log.paymentNotFound'
              ),
              'error'
            )
          );
        }
      });
  }

  savePaymentLineInStore(datastore: DataStore, id?: number) {
    const tmpStore = new DataStore();
    tmpStore.data = this.getCurrentContext();
    const dataReformat = Object.assign(
      {},
      this.getCurrentContext(datastore, tmpStore)
    );
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('Z_PaymentLine', dataReformat, id);
  }
  savePaymentLine(lineDataStore: DataStore, callback = null) {
    const isNew = lineDataStore.data['Z_PaymentLine_ID'] == null;
    if (
      lineDataStore.data['AD_Org_ID'] instanceof Object &&
      lineDataStore.data['C_Charge_ID'] instanceof Object &&
      lineDataStore.data['PayDetailAmt'] != null &&
      lineDataStore.data['Description'] != null
    ) {
      const id =
        lineDataStore.data['Z_PaymentLine_ID'] instanceof Object
          ? lineDataStore.data['Z_PaymentLine_ID'].id
          : lineDataStore.data['Z_PaymentLine_ID'];
      const sub$ = this.savePaymentLineInStore(lineDataStore, id).subscribe(
        (result) => {
          lineDataStore.data['Z_PaymentLine_ID'] = result['Z_PaymentLine_ID'];
          this.store.syncDataChanges(lineDataStore, result, false, true);
          if (isNew) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.success'),
                this.translateService.instant(
                  'specificWindow.quickExpenseRevenue.saveLineSuccess'
                ),
                'success'
              )
            );
          }
          if (callback) {
            callback();
          }
          this.updateHeader();
          sub$.unsubscribe();
        }
      );
    } else if (!isNew) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant(
            'specificWindow.quickExpenseRevenue.missingParams'
          ),
          'warning'
        )
      );
    }
  }
  /**
   * Permet d'ajouter visuellement une nouvelle ligne de commande + création d'un nouveau datastore
   */
  newLine() {
    if (
      this.dataStore &&
      this.dataStore.data &&
      this.dataStore.data['C_Payment_ID'] &&
      this.dataStore.data['DocStatus'] &&
      this.dataStore.data['DocStatus'] !== 'CO' &&
      this.dataStore.data['DocStatus'].id !== 'CO' &&
      !this.isProcessed
    ) {
      const lineDataStore = this.dataLines.find(
        (line) => !line.data['Z_PaymentLine_ID']
      );
      if (lineDataStore) {
        this.savePaymentLine(lineDataStore, this.newLine.bind(this));
      } else {
        this.nbLines++;
        this.dataLines.push(
          this.store.newSpecificWindowData(this.formId, this.dataStore)
        );
        this.dataLines[this.dataLines.length - 1].data['Line'] =
          this.dataLines.length * 10;

        this.lineMetaData.forEach((metaData) => {
          const compRef = this.createCustomDesignItem(
            metaData,
            true,
            this.dataLines.length - 1
          );
          // make autocomplete overlay visible outside overflow
          compRef.instance['isInsideOverflow'] = true;
          if (compRef) {
            if (metaData.columnName !== 'Description') {
              compRef.location.nativeElement.setAttribute(
                'tabindex',
                this.tabindex++
              );
            } else {
              compRef.location.nativeElement.setAttribute('tabindex', -1);
            }
            this.dataContainers.pop();
            (<AbstractDataContainer>compRef.instance).data[
              'fieldValueModifiedSub'
            ].unsubscribe();
            (<AbstractDataContainer>(
              compRef.instance
            )).fieldValueModified.subscribe((dataStored) => {
              const item = this.specificData.items.find((specificItem) => {
                return metaData.type === CustomDesignItemType.FIELD
                  ? metaData.columnName === specificItem.data.columnName
                  : metaData.tableName === specificItem.name;
              });
              this.notifyFromDataChange(
                item,
                this.dataLines.indexOf(dataStored)
              );
            });
            if (!this.dataContainersLine[this.dataLines.length - 1]) {
              this.dataContainersLine[this.dataLines.length - 1] = [];
            }
            this.dataContainersLine[this.dataLines.length - 1].push(
              <AbstractDataContainer>compRef.instance
            );

            (<AbstractDataContainer>compRef.instance).setNewData(
              this.dataLines[this.dataLines.length - 1]
            );
          }
        });
      }

      this.gotoBottomOfLines();
    } else {
      this.checkMissingField();
    }
  }
  private gotoBottomOfLines() {
    setTimeout(() => {
      this.linesContainer.nativeElement.scrollTop =
        this.linesContainer.nativeElement.scrollHeight -
        this.linesContainer.nativeElement.clientHeight;
    }, 100);
  }
  removeLine(lineDataStore: DataStore, index: number) {
    if (
      this.dataStore &&
      this.dataStore.data['DocStatus'] &&
      !this.isProcessed
    ) {
      const id =
        lineDataStore.data['Z_PaymentLine_ID'] instanceof Object
          ? lineDataStore.data['Z_PaymentLine_ID'].id
          : lineDataStore.data['Z_PaymentLine_ID'];
      let obs = of(null);
      if (id) {
        obs = this.store.deleteWindowSpecificData(
          'Z_PaymentLine',
          lineDataStore,
          id
        );
      }
      this.subscriptions.push(
        obs.subscribe((result) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
          for (let i = index + 1; i < this.dataLines.length; i++) {
            this.lineMetaData.forEach((metaData) => {
              const element = (<ViewContainerRef>(
                this[metaData.vcr].toArray()[i]
              )).detach();
              (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(
                element
              );
            });
          }
          this.dataLines.splice(index, 1);
          this.dataContainersLine.splice(index, 1);
          this.nbLines--;
          if (result) {
            this.updateHeader();
          }
        })
      );
    }
  }

  //#endregion CRUD
  //#region LOGS

  setCurrencySymbol(currencyId: number) {
    const component = this.getDatacontainer('C_Currency_ID');
    if (component) {
      this.cacheService
        .getCurrencySymbol({ tableName: TableName.CURRENCY, id: currencyId })
        .subscribe((res: any) => {
          if (res) {
            this.currency = res;
          }
        });
      // }
    }
  }

  //#region AD DATA
  initAD_Data() {
    this.tableID = 335;
    this.paymentTabID = 1000362;
    this.paymentLineTabID = 1000363;
    this.windowID = 1000134;
    this.subscriptions.push(
      this.store
        .getDataGrid(getTabDataRequest(), true)
        .subscribe((response) => {
          if (!this.data) {
            this.data = {};
          }
          if (response && response.data) {
            const paymentLineTab = response.data.find(
              (tab) => tab['TABLENAME'] === 'Z_PaymentLine'
            );
            const paymentTab = response.data.find(
              (tab) => tab['TABLENAME'] === 'C_Payment'
            );
            if (paymentLineTab) {
              this.paymentLineTabID = paymentLineTab['AD_TAB_ID'];
            }
            if (paymentTab) {
              this.data.DocFilters = paymentTab['DOCFILTERS'];
              this.data.TaggedColumns = paymentTab['TAGGEDCOLUMNS'];
              this.tableID = paymentTab['AD_TABLE_ID'];
              this.paymentTabID = paymentTab['AD_TAB_ID'];
              this.windowID = paymentTab['AD_WINDOW_ID'];
            }
          }
        })
    );
  }
  //#endregion AD DATA
}
//#region Model
