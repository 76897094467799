import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
export function getNullValueInData(data: any, columnNames: string[]) {
  let nullValue = null;
  columnNames.forEach((columnName) => {
    const value = data[columnName];
    if (
      value == null ||
      value == undefined ||
      value == '' ||
      (value.trim && value.trim() == '') ||
      (columnName === 'AD_Org_ID' && value.id <= 0)
    ) {
      nullValue = columnName;
      return;
    }
  });
  return nullValue;
}

export function getTabDataRequest() {
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select tv.tableName,tv.ad_tab_id,tv.ad_table_id,tv.ad_window_id,tv.taggedcolumns,tv.docfilters,w.value FROM ad_tab_v tv INNER JOIN ad_window w on tv.ad_window_id=w.ad_window_id WHERE (tv.tablename='C_Payment' AND w.value='D�penses et Recettes Directes') OR (tv.tablename='Z_PaymentLine' AND w.value='D�penses et Recettes Directes')) TabRequest`,
      filterModel: {}
    }
  };
}
export function getPaymentRequest(c_payment_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Payment',
      ad_language,
      filterModel: {
        C_Payment_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_payment_id]
        }
      }
    }
  };
}
export function getPaymentLineRequest(c_payment_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_PaymentLine',
      ad_language,
      filterModel: {
        C_Payment_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_payment_id]
        }
      }
    }
  };
}
export function getLogMessageRequest(ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_MESSAGE',
      ad_language,
      filterModel: {
        value: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: ['SC_ExpenseRevenue']
        }
      }
    }
  };
}

//#endregion DATAGRID REQUEST

export function getLineCustomDesignItems() {
  return [
    {
      vcr: 'vcrOrgs',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Org_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrPayDetailAmts',
      type: CustomDesignItemType.FIELD,
      columnName: 'PayDetailAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrCharges',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Charge_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrUser1s',
      type: CustomDesignItemType.FIELD,
      columnName: 'User1_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrProjects',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Project_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrLocals',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_Local_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBeneficiaires',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Beneficiaire_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrUser2s',
      type: CustomDesignItemType.FIELD,
      columnName: 'User2_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrRessources',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_Ressource_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDescriptions',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PayAmtTot',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'PayAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'Y_IMMOTYPE',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'Y_FINANCEUR_SUBV',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    // hidden fields for callout
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Client_ID',
      cssClass: 'group-input'
    },

    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsDass',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsOthers',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_ID',
      cssClass: 'group-input'
    }
  ];
}
export function getHeaderCustomDesignItems() {
  return [
    {
      vcr: 'vcrCopyLines',
      type: CustomDesignItemType.FIELD,
      columnName: 'CopyLines',
      cssClass: 'CopyLines',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBankCC',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_Bank_CC_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBankAccount',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BankAccount_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBankDesc',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_BankDesc',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDocumentNo',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocumentNo',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDocType',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocType_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSubPaymentRule',
      type: CustomDesignItemType.FIELD,
      columnName: 'ZSubPaymentRule_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDateAcct',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateAcct',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrCheckNo',
      type: CustomDesignItemType.FIELD,
      columnName: 'CheckNo',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrPayAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'PayAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrCurrency',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Currency_ID',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Client_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Org_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Payment_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateTrx',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DueDate',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_MotifEco',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Activity_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Campaign_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_OrgTrx_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'OverUnderAmt',
      cssClass: 'group-input',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'TenderType',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_RoutingNo',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_AccountNo',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Micr',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Name',
      cssClass: 'group-input'
    },

    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processed',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_City',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Zip',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_State',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Country',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Ident_DL',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Ident_SSN',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_EMail',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'TaxAmt',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'PONum',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'VoiceAuthCode',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Orig_TrxID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsApproved',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_Result',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_RespMsg',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_PnRef',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_AuthCode',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_AvsZip',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_AvsAddr',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocStatus',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocAction',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsAllocated',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Posted',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReconciled',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'isEncaiss',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_BPTftSelection_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsOnline',
      cssClass: 'group-input'
    },

    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReceipt',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_ConversionType_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'A_Street',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsDelayedCapture',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_PnRef_DC',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_PaymentBatch_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'R_AuthCode_DC',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'ChargeAmt',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processing',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Payment_ID',
      cssClass: 'group-input'
    },
    {
      vcr: 'vcrProcessPayment',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processing',
      cssClass: 'group-input'
    }
  ];
}
