<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast payment']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <div class="fast-expense-revenue-grid">
    <!-- title -->
    <div class="title">
      <h1>{{ 'specificWindow.quickExpenseRevenue.title' | translate }} ( {{ docStatus }} )</h1>
      <p-toolbar>
        <button
          pButton
          tooltipPosition="left"
          showDelay="250"
          pTooltip="{{ 'specificWindow.quickExpenseRevenue.joinFiles' | translate }}"
          type="button"
          icon="icon-attach3x"
          data-cy="button-attachFile"
          style="overflow: visible"
          (mouseup)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })"
        >
          <span
            *ngIf="nbUploadedFiles !== undefined && nbUploadedFiles > 0"
            class="iupics-badge iupics-badge-count"
            style="top: 0; right: -2px"
            >{{ nbUploadedFiles > 9 ? '9+' : nbUploadedFiles }}</span
          >
        </button>
        <button
          pButton
          tooltipPosition="right"
          showDelay="250"
          pTooltip="{{ 'specificWindow.quickExpenseRevenue.settings' | translate }}"
          type="button"
          icon="fas fa-cog"
          (click)="isSidebarOpen = !isSidebarOpen"
        ></button>
      </p-toolbar>
    </div>
    <!-- left -->
    <div class="left subgrid">
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.bankCC' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBankCC></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.bankAccount' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBankAccount></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.docType' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrDocType></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.subPaymentRule' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrSubPaymentRule></ng-template>
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="right subgrid col-2">
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.bankDesc' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrBankDesc></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.dateAcct' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrDateAcct></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.documentNo' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrDocumentNo></ng-template>
        </div>
      </div>

      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.checkNo' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrCheckNo></ng-template>
        </div>
      </div>
      <div class="group group-row col-2">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.description' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrDescription></ng-template>
        </div>
      </div>

      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.payAmt' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrPayAmt></ng-template>
        </div>
      </div>
      <div class="group group-row">
        <label>{{ 'specificWindow.quickExpenseRevenue.headerData.currency' | translate }}</label>
        <div class="flex-grow">
          <ng-template #vcrCurrency></ng-template>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="table">
      <div class="row-btn pt-0 separator-border">
        <ng-template #vcrCopyLines></ng-template>
      </div>
      <p-scrollPanel [style]="{ width: '100%' }">
        <div id="page-wrap">
          <!-- payment line -->
          <div #linesContainer class="items-container">
            <table id="items">
              <colgroup>
                <col class="orgCol" />
                <col class="payDetailAmtCol" />
                <col class="chargeCol" />
                <col class="user1Col" />
                <col class="projectCol" />
                <col class="localCol" />
                <col class="beneficiaireCol" />
                <col class="user2Col" />
                <col class="ressourceCol" />
                <col class="descriptionCol" />
                <col class="deleteCol" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ translations.org }}</th>
                  <th>{{ translations.payDetailAmt }}</th>
                  <th>{{ translations.charge }}</th>
                  <th>{{ translations.user1 }}</th>
                  <th>{{ translations.project }}</th>
                  <th>{{ translations.local }}</th>
                  <th>{{ translations.beneficiaire }}</th>
                  <th>{{ translations.user2 }}</th>
                  <th>{{ translations.ressource }}</th>
                  <th>{{ translations.description }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of nbLines | fakeArray; let i = index">
                  <tr class="item-row" [ngClass]="{ notPersisted: !dataLines[i] || !dataLines[i].data['Z_PaymentLine_ID'] }">
                    <td [attr.data-label]="translations.org">
                      <ng-template #vcrOrgs></ng-template>
                    </td>
                    <td [attr.data-label]="translations.payDetailAmt">
                      <ng-template #vcrPayDetailAmts></ng-template>
                    </td>
                    <td [attr.data-label]="translations.charge">
                      <ng-template #vcrCharges></ng-template>
                    </td>
                    <td [attr.data-label]="translations.user1">
                      <ng-template #vcrUser1s></ng-template>
                    </td>
                    <td [attr.data-label]="translations.project">
                      <ng-template #vcrProjects></ng-template>
                    </td>
                    <td [attr.data-label]="translations.local">
                      <ng-template #vcrLocals></ng-template>
                    </td>
                    <td [attr.data-label]="translations.beneficiaire">
                      <ng-template #vcrBeneficiaires></ng-template>
                    </td>
                    <td [attr.data-label]="translations.user2">
                      <ng-template #vcrUser2s></ng-template>
                    </td>
                    <td [attr.data-label]="translations.ressource">
                      <ng-template #vcrRessources></ng-template>
                    </td>
                    <td [attr.data-label]="translations.description">
                      <ng-template #vcrDescriptions></ng-template>
                    </td>
                    <div style="display: none"><ng-template #vcrOthers></ng-template></div>
                    <td class="deleteButton" data-label="">
                      {{ item }}
                      <button
                        *ngIf="i < $any(dataLines).length"
                        [ngClass]="[
                          dataStore &&
                          dataStore.data['DocStatus'] &&
                          (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                            ? 'disabledButton'
                            : ''
                        ]"
                        tooltipPosition="top"
                        showDelay="250"
                        pTooltip="{{ 'generic.delete' | translate }}"
                        pButton
                        icon="icon-delete"
                        (click)="removeLine(dataLines[i], i)"
                      ></button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="row-btn separator-border" id="hiderow">
            <button
              pButton
              id="addrow"
              [ngClass]="[
                dataStore &&
                (!dataStore.data['C_Payment_ID'] ||
                  (dataStore.data['DocStatus'] &&
                    (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')))
                  ? 'disabledButton'
                  : '',
                'button p-button-secondary'
              ]"
              (click)="newLine()"
              label="{{ 'specificWindow.quickExpenseRevenue.addrow' | translate }}"
              icon="icon-add"
            ></button>
          </div>
          <!-- total -->
          <div class="total-header subgrid col-3 auto-row">
            <div class="group group-row grandTotal">
              <label class="total">
                {{ 'specificWindow.quickExpenseRevenue.grandTotal' | translate }}
              </label>
              <div class="flex-grow">
                <iu-input-number-ui
                  [isStandalone]="true"
                  [isReadOnly]="true"
                  [fieldValue]="this.grandTotal"
                  label=""
                  [data]="grandTotalData"
                  [currency]="currency"
                ></iu-input-number-ui>
              </div>
            </div>
            <div class="group group-row justify-center col-3 row-btn">
              <button
                *ngIf="!isProcessed"
                [ngClass]="['button p-button-alert cancelButton']"
                (click)="$event.preventDefault(); $event.stopPropagation(); cancelDoc()"
                pButton
                label=" {{ 'specificWindow.quickExpenseRevenue.cancel' | translate }}"
                icon="fas fa-ban"
              ></button>
              <button
                [ngClass]="[
                  isProcessed ||
                  (dataStore &&
                    dataStore.data &&
                    (!dataStore.data['C_Payment_ID'] ||
                      (dataStore.data['DocStatus'] &&
                        (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO'))))
                    ? 'disabledButton'
                    : '',
                  'button p-button-success saveButton'
                ]"
                (click)="$event.preventDefault(); $event.stopPropagation(); processPayment()"
                pButton
                label="{{ 'specificWindow.quickExpenseRevenue.save' | translate }}"
                icon="{{
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                    ? 'fa fa-ban'
                    : 'fa fa-check'
                }}"
              ></button>
              <button
                *ngIf="dataStore && dataStore.data && dataStore.data['C_Payment_ID']"
                pButton
                class="button p-button-warning"
                (click)="$event.preventDefault(); $event.stopPropagation(); resetPayment()"
                label="{{ 'specificWindow.quickExpenseRevenue.newPayment' | translate }}"
                icon="fa fa-plus"
              ></button>
            </div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>

  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="p-sidebar-md">
    <div class="sidebar-header">
      <h1>
        {{ 'specificWindow.quickExpenseRevenue.settings' | translate }}
      </h1>
      <button
        pButton
        tooltipPosition="right"
        showDelay="250"
        pTooltip="{{ 'generic.close' | translate }}"
        type="button"
        icon="icon-next"
        (click)="isSidebarOpen = !isSidebarOpen"
      ></button>
    </div>
    <ng-template #vcrSettings></ng-template>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
<div *ngIf="displayProcessUI">
  <iu-modal-ui
    (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
    [hasCloseBtn]="true"
    [angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
    [contentType]="'process'"
  >
    <iu-process-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [windowId]="processId"
      [parentComponent]="this"
      [isModal]="true"
    ></iu-process-ui>
  </iu-modal-ui>
</div>
<ng-container *ngIf="displayJoinFilesPanel">
  <iu-modal-ui
    [title]="'joinFiles.title'"
    [hasCloseBtn]="true"
    [contentType]="'joinFiles'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })"
  >
    <iu-join-file-ui
      [dsKey]="dsKey"
      [adTable_ID]="tableID"
      [adTab_ID]="paymentTabID"
      [(nbUploadedFiles)]="nbUploadedFiles"
      [data]="data"
    ></iu-join-file-ui>
  </iu-modal-ui>
</ng-container>
